<template lang="html">
  <div class="ActivityCreation">
    <div class="LoadingIcon" v-if="showStep3Spinner">
      <i class="fas fa-spinner"></i>
    </div> 
    <div class="ActivityCreation--Header">
    </div>
    <div class="ActivityCreation__Content">
      <div class="ActivityCreation__Content--Header">
        <h1 class="ActivityCreation__Content--pageTitle">{{$t('new_activity_text')}}</h1>
        <div class="ActivityCreation__Content--Steps">
          <div :class="{'active':STEP==1,'completed':STEP>1}">
            <p>1</p>
            <p>{{$t('new_activity_general')}}</p>
          </div>
          <div :class="{'active':STEP==2,'completed':STEP>2}">
            <p>2</p>
            <p>{{$t('activity')}}</p>
          </div>
          <div :class="{'active':STEP==3}">
            <p>3</p>
            <p>{{$t('users')}}</p>
          </div>
        </div>
      </div>
      <div class="ActivityCreation__Content--Box" v-if="STEP == 1">
        <form class="ActivityCreation__Form">
          <div class="ActivityCreation__HeaderImage" :key="headerImageKey" :style="{'background-image':'url(\''+activityForm.headerImage+'\')'}">
            <Dropzone
              ref="imageHeaderUploader"
              class="ActivityCreation__Media--Uploader"
              :uploadUrl="fileUploadPath"
              :uploadHeaders="uploadHeaders"
              :uploadText="getSVG()"
              :acceptedFiles="'image/jpg,image/jpeg,image/png'"
              :maxFiles="1"
              v-on:file-uploaded="imageHeaderUploadSuccess">
            </Dropzone>
            <p>{{$t('image_recomentation_1200x200')}}</p>
            <span v-on:click="imageHeaderDelete()" v-if="activityForm.headerImage != '' && activityForm.headerImage != null && activityForm.headerImage != undefined"><i class="fa fa-times" aria-hidden="true"></i></span>
          </div>
          <div class="ActivityCreation__TitleLabs">
            <div class="">
              <p>{{$t('new_activity_title')}}</p>
              <CommunityInput class="titleInput" ref="title" type="text" :required="true" :placeholder="$t('activity_title')" v-model="activityForm.title" v-on:key-up="dataIsUpdated = true"/>
            </div>
            <div class="ActivityCreation__Labs">
              <p>{{$t('new_activity_labs_for_participating')}}</p>
              <CommunityInput type="number" placeholder="0" v-model="activityForm.activityParticipationLabs" v-on:input="dataIsUpdated = true"/>
            </div>
          </div>
          <div class="ActivityCreation--NotifActivation">
              <p class="ActivityCreation--NotifActivationTitle">{{ $t('activity_step1_disable_notif') }}</p>
              <SwitchInput v-model="activityForm.muteNotifications"/>
            </div>
          <div class="ActivityCreation__Raffle">
            <div class="ActivityCreation--RaffleActivation">
              <p class="ActivityCreation--RaffleTitle">{{ $t('activity_step1_raffle_activation') }}</p>
              <SwitchInput v-model="activityForm.activityRaffleData.raffleEnabled"/>
            </div>
            <div class="ActivityCreation--RaffleContent" v-show="activityForm.activityRaffleData.raffleEnabled">
              <p class="ActivityCreation--RaffleInfo">{{ $t('activity_step1_raffle_msg') }}</p>
              <div>
                <p>{{ $t('activity_step1_raffle_winners') }}</p>
                <CommunityInput type="number" v-model="activityForm.activityRaffleData.winnersNum"/>
              </div>
              <div>
                <p>{{ $t('activity_step1_raffle_reward') }}</p>
                <CommunityInput type="text" v-model="activityForm.activityRaffleData.prizeDescription"/>
              </div>
              <div>
                <p>{{ $t('activity_step1_raffle_image') }}</p>
                <div class="ActivityCreation--RaffleImage">
                  <Dropzone
                    ref="raffleImageUploader"
                    class="ActivityCreation__Media--Uploader"
                    :uploadUrl="fileUploadPath"
                    :uploadHeaders="uploadHeaders"
                    :uploadText="getSVG()"
                    :acceptedFiles="'image/jpg,image/jpeg,image/png'"
                    :maxFiles="1"
                    v-on:file-uploaded="imageRaffleUploadSuccess">
                  </Dropzone>
                  <div class="ActivityCreation__Media--Item" v-show="activityForm.activityRaffleData.prizeImage">
                    <img :src="activityForm.activityRaffleData.prizeImage">
                    <i class="fas fa-trash-alt" v-on:click="activityForm.activityRaffleData.prizeImage = null"></i>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          <div class="ActivityCreation__ActivitySelector">
            <p class="ActivityCreation__ActivitySelector--title">{{$t('new_activity_select_type')}}</p>
            <div class="ActivityCreation__ActivitySelector--Activities">
              <div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('WAT_SURVEY') || allowedActivities.includes('EL_SURVEY')" :class="{'active':activityForm.type == 'WAT_SURVEY'}" v-on:click="changeActivityType('WAT_SURVEY')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/survey-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_el_survey')}}</h1>
                  <p>{{$t('new_activity_type_info_survey')}}</p>
                </div>
              </div>
              <div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('WAT_CHAT')" :class="{'active':activityForm.type == 'WAT_CHAT'}" v-on:click="changeActivityType('WAT_CHAT')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/chat-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_wat_chat')}}</h1>
                  <p>{{$t('new_activity_type_info_chat')}}</p>
                </div>
              </div>
              <!--<div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('WAT_CHAT_ONLINE')" :class="{'active':activityForm.type == 'WAT_CHAT_ONLINE'}" v-on:click="changeActivityType('WAT_CHAT_ONLINE')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/chat-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_wat_chat_online')}}</h1>
                  <p>{{$t('new_activity_type_info_chat_online')}}</p>
                </div>
              </div>-->
              <div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('WAT_OPINION')" :class="{'active':activityForm.type == 'WAT_OPINION'}" v-on:click="changeActivityType('WAT_OPINION')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/opinion-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_wat_opinion')}}</h1>
                  <p>{{$t('new_activity_type_info_opinion')}}</p>
                </div>
              </div>
              <div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('WAT_TASK')" :class="{'active':activityForm.type == 'WAT_TASK'}" v-on:click="changeActivityType('WAT_TASK')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/task-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_wat_task')}}</h1>
                  <p>{{$t('new_activity_type_info_task')}}</p>
                </div>
              </div>
              <div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('WAT_POLL')" :class="{'active':activityForm.type == 'WAT_POLL'}" v-on:click="changeActivityType('WAT_POLL')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/poll-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_wat_poll')}}</h1>
                  <p>{{$t('new_activity_type_info_poll')}}</p>
                </div>
              </div>
              <div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('VIDEOCHAT')" :class="{'active':activityForm.type == 'VIDEOCHAT'}" v-on:click="changeActivityType('VIDEOCHAT')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/video-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_wat_videochat')}}</h1>
                  <p>{{$t('new_activity_type_info_video_chat')}}</p>
                </div>
              </div>
              <div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('EL_HEATMAP')" :class="{'active':activityForm.type == 'EL_HEATMAP'}" v-on:click="changeActivityType('EL_HEATMAP')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/heatmap-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_el_heatmap')}}</h1>
                  <p>{{$t('new_activity_type_info_heatmap')}}</p>
                </div>
              </div>
              <div class="ActivityCreation__ActivitySelector--Activity" v-show="allowedActivities.includes('WAT_UX')" :class="{'active':activityForm.type == 'WAT_UX'}" v-on:click="changeActivityType('WAT_UX')">
                <div class="ActivityCreation__ActivitySelector--logo">
                  <img src="@/assets/img/activities/watux-icon.svg"/>
                </div>
                <div class="ActivityCreation__ActivitySelector--info">
                  <h1>{{$t('activity_type_wat_ux')}}</h1>
                  <p>{{$t('new_activity_type_info_watux')}}</p>
                </div>
              </div>
            </div>
          </div>
          <CommunityInput ref="description" withImages  :type="'html'" v-model="activityForm.description" :required="true" :placeholder="$t('form_description')" :stopFirstValidation="true" v-on:key-up="dataIsUpdated = true"/>
          <div class="ActivityCreation__Dates">
            <CommunityInput :required="true" v-model="activityForm.dateFrom" v-on:change="changedDate($event);dataIsUpdated = true" ref="dateFromPicker" :placeholder="$t('date_start')"  :lang="communityInfo.language ? communityInfo.language : 'es'" format="dd-MM-yyyy HH:mm" type="datetime"/>
            <CommunityInput :required="true" v-model="activityForm.dateTo" v-on:change="dataIsUpdated = true" ref="dateToPicker" :placeholder="$t('date_finished')" :lang="communityInfo.language ? communityInfo.language : 'es'" format="dd-MM-yyyy HH:mm" type="datetime" />
          </div>
          <div class="ActivityCreation__Media" v-show="activityForm.type != 'EL_HEATMAP'">
            <div class="ActivityCreation__Media--Wrapper">
              <h1>{{$t('new_activity_gallery_img')}}</h1>
              <div class="ActivityCreation__Media--List">
                <Dropzone
                  ref="imageUploader"
                  class="ActivityCreation__Media--Uploader"
                  :uploadUrl="fileUploadPath"
                  :uploadHeaders="uploadHeaders"
                  :uploadText="getSVG()"
                  :acceptedFiles="'image/jpg,image/jpeg,image/png'"
                  :maxFiles="1"
                  v-on:file-uploaded="imageUploadSuccess">
                </Dropzone>
                <div class="ActivityCreation__Media--Item" v-for="img in images">
                  <img :src="img.path">
                  <i class="fas fa-trash-alt" v-on:click="imageDelete(img)"></i>
                </div>
              </div>
            </div>
            <div class="ActivityCreation__Media--Wrapper">
              <h1>{{$t('new_activity_gallery_video')}}</h1>
              <div class="ActivityCreation__Media--List">
                <Dropzone
                  ref="videoUploader"
                  class="ActivityCreation__Media--Uploader"
                  :uploadUrl="videoUploadPath"
                  :uploadHeaders="uploadHeaders"
                  :uploadText="getSVG()"
                  :acceptedFiles="'video/*'"
                  :maxFiles="1"
                  v-on:file-uploaded="videoUploadSuccess">
                </Dropzone>
                <div class="ActivityCreation__Media--Item" v-for="(video,index) in videos" :key="video.path">
                  <video>
                    <source :src="video.path" type="video/mp4">
                    <source :src="video.path" type="video/ogg">
                  </video>
                  <input type="text" :placeholder="$t('video_name')" v-model="video.videoTitle">
                  <i class="fas fa-trash-alt" v-on:click="removeVideo(video,index)"></i>
                </div>
              </div>
            </div>
            <div class="ActivityCreation__Media--Wrapper">
              <h1>{{$t('new_activity_gallery_files')}}</h1>
              <div class="ActivityCreation__Media--List">
                <Dropzone
                  ref="fileUploader"
                  class="ActivityCreation__Media--Uploader"
                  :uploadUrl="fileUploadPath"
                  :uploadHeaders="uploadHeaders"
                  :uploadText="getSVG()"
                  :maxFiles="1"
                  v-on:file-uploaded="fileUploadSuccess">
                </Dropzone>
                <div class="ActivityCreation__Media--Item" v-for="file in files">
                  <div>
                    <span>{{file.name}}</span>
                  </div>
                  <i class="fas fa-trash-alt" v-on:click="removeFile(file)"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="ActivityCreation__YtVideo" v-show="activityForm.type != 'EL_HEATMAP'">
            <CommunityInput :required="false" :placeholder="$t('video_youtube')" type="youtube" v-model="activityForm.youTubeVideo"></CommunityInput>
          </div>
        </form>
        <div class="ActivityCreation__Actions">
          <CommunityButton class="ActivityCreation__Actions--Next" v-on:click="submitStep1(null)">{{$t('new_activity_next')}}</CommunityButton>
          <CommunityButton :disabled="!dataIsUpdated" v-if="activityForm.identifier" class="ActivityCreation__Actions--Next" v-on:click="submitStep1(true)">{{$t('action_save')}}</CommunityButton>
        </div>
        <CommunityErrors :errors="errors" v-show="errors.length > 0"/>
        <div class="ActivityCreation__Options">
          <button class="ActivityCreation__Options--Exit" v-on:click="exit()">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2168 5.21094C15.7206 5.21094 15.3184 4.80871 15.3184 4.3125V1.79688H2.4707V21.2031H15.3184V18.6875C15.3184 18.1913 15.7206 17.7891 16.2168 17.7891C16.713 17.7891 17.1152 18.1913 17.1152 18.6875V22.1016C17.1152 22.5978 16.713 23 16.2168 23H1.57227C1.07606 23 0.673828 22.5978 0.673828 22.1016V0.898438C0.673828 0.402231 1.07606 0 1.57227 0H16.2168C16.713 0 17.1152 0.402231 17.1152 0.898438V4.3125C17.1152 4.80871 16.713 5.21094 16.2168 5.21094Z" fill="#BDBDBD"/>
              <path d="M22.0628 12.1353L19.6487 14.5494C19.2978 14.9003 18.729 14.9003 18.3781 14.5494C18.0272 14.1986 18.0272 13.6297 18.3781 13.2788L19.2585 12.3985L11.8159 12.3985C11.3198 12.3985 10.9175 11.9963 10.9175 11.5001C10.9175 11.0039 11.3197 10.6016 11.8159 10.6016L19.2586 10.6016L18.3781 9.72116C18.0273 9.37036 18.0273 8.80147 18.3781 8.45058C18.729 8.0997 19.2979 8.09979 19.6487 8.45063L22.0628 10.8647C22.0629 10.8648 22.0629 10.8649 22.063 10.865C22.2438 11.0459 22.3194 11.255 22.326 11.5C22.326 11.7422 22.223 11.9751 22.0628 12.1353Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_exit')}}
          </button>
          <button class="ActivityCreation__Options--Next" v-on:click="submitStep1()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.6164 11.0675L14.9491 2.40006C14.7017 2.15265 14.372 2.01685 14.0204 2.01685C13.6684 2.01685 13.3388 2.15284 13.0914 2.40006L12.3045 3.18718C12.0573 3.4342 11.9211 3.76415 11.9211 4.11595C11.9211 4.46756 12.0573 4.80863 12.3045 5.05565L17.3608 10.1231H1.29657C0.572287 10.1231 0 10.6901 0 11.4146V12.5274C0 13.2519 0.572287 13.8761 1.29657 13.8761H17.4182L12.3047 18.9718C12.0575 19.2192 11.9213 19.5402 11.9213 19.892C11.9213 20.2434 12.0575 20.5691 12.3047 20.8163L13.0916 21.6009C13.339 21.8483 13.6686 21.9831 14.0206 21.9831C14.3722 21.9831 14.7019 21.8465 14.9493 21.5991L23.6166 12.9319C23.8646 12.6837 24.001 12.3524 24 12.0002C24.0008 11.6468 23.8646 11.3153 23.6164 11.0675Z" fill="white"/>
            </svg>
            {{$t('new_activity_next')}}
          </button>
        </div>
      </div>
      <div class="ActivityCreation__Content--Box" v-if="STEP == 2 && activityForm.type == 'WAT_CHAT'" >
        <TopicsCreation ref="TopicsCreation" class="ActivityCreation__Topics" :debateTopics="debateTopics" :debateTopicsMinForParticipation="debateTopicsMinForParticipation" v-on:add="addDebateTopic($event)" v-on:delete="deleteDebateTopic($event)"/>
        <div class="ActivityCreation__Actions">
          <CommunityButton class="ActivityCreation__Actions--Previous" v-on:click="returnStep(1)">{{$t('new_activity_step_back',{step:1})}}</CommunityButton>
          <CommunityButton class="ActivityCreation__Actions--Next" v-on:click="submitStep2Topics(null)">{{$t('new_activity_next')}}</CommunityButton>
          <CommunityButton :disabled="!dataIsUpdated" class="ActivityCreation__Actions--Next" v-on:click="submitStep2Topics(true)">{{$t('action_save')}}</CommunityButton>
        </div>
        <CommunityErrors :errors="errors" v-show="errors.length > 0"/>
        <div class="ActivityCreation__Options">
          <button class="ActivityCreation__Options--Exit" v-on:click="exit()">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2168 5.21094C15.7206 5.21094 15.3184 4.80871 15.3184 4.3125V1.79688H2.4707V21.2031H15.3184V18.6875C15.3184 18.1913 15.7206 17.7891 16.2168 17.7891C16.713 17.7891 17.1152 18.1913 17.1152 18.6875V22.1016C17.1152 22.5978 16.713 23 16.2168 23H1.57227C1.07606 23 0.673828 22.5978 0.673828 22.1016V0.898438C0.673828 0.402231 1.07606 0 1.57227 0H16.2168C16.713 0 17.1152 0.402231 17.1152 0.898438V4.3125C17.1152 4.80871 16.713 5.21094 16.2168 5.21094Z" fill="#BDBDBD"/>
              <path d="M22.0628 12.1353L19.6487 14.5494C19.2978 14.9003 18.729 14.9003 18.3781 14.5494C18.0272 14.1986 18.0272 13.6297 18.3781 13.2788L19.2585 12.3985L11.8159 12.3985C11.3198 12.3985 10.9175 11.9963 10.9175 11.5001C10.9175 11.0039 11.3197 10.6016 11.8159 10.6016L19.2586 10.6016L18.3781 9.72116C18.0273 9.37036 18.0273 8.80147 18.3781 8.45058C18.729 8.0997 19.2979 8.09979 19.6487 8.45063L22.0628 10.8647C22.0629 10.8648 22.0629 10.8649 22.063 10.865C22.2438 11.0459 22.3194 11.255 22.326 11.5C22.326 11.7422 22.223 11.9751 22.0628 12.1353Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_exit')}}
          </button>
          <button class="ActivityCreation__Options--Back" v-on:click="returnStep(1)">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.383628 10.9325L9.05087 19.5999C9.29828 19.8473 9.62803 19.9832 9.97964 19.9832C10.3316 19.9832 10.6612 19.8472 10.9086 19.5999L11.6955 18.8128C11.9427 18.5658 12.0789 18.2359 12.0789 17.884C12.0789 17.5324 11.9427 17.1914 11.6955 16.9443L6.63918 11.8769L22.7034 11.8769C23.4277 11.8769 24 11.3099 24 10.5854L24 9.47261C24 8.74813 23.4277 8.12393 22.7034 8.12393L6.58182 8.12393L11.6953 3.02817C11.9425 2.78076 12.0787 2.45978 12.0787 2.10798C12.0787 1.75657 11.9425 1.43091 11.6953 1.1837L10.9084 0.399119C10.661 0.151707 10.3314 0.0168788 9.97945 0.0168788C9.62784 0.0168788 9.29809 0.153462 9.05067 0.400874L0.383433 9.06812C0.135436 9.31631 -0.000952771 9.64763 2.3761e-05 9.99982C-0.000758283 10.3532 0.135436 10.6847 0.383628 10.9325Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_back')}}
          </button>
          <button class="ActivityCreation__Options--Next" v-on:click="submitStep2Topics()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.6164 11.0675L14.9491 2.40006C14.7017 2.15265 14.372 2.01685 14.0204 2.01685C13.6684 2.01685 13.3388 2.15284 13.0914 2.40006L12.3045 3.18718C12.0573 3.4342 11.9211 3.76415 11.9211 4.11595C11.9211 4.46756 12.0573 4.80863 12.3045 5.05565L17.3608 10.1231H1.29657C0.572287 10.1231 0 10.6901 0 11.4146V12.5274C0 13.2519 0.572287 13.8761 1.29657 13.8761H17.4182L12.3047 18.9718C12.0575 19.2192 11.9213 19.5402 11.9213 19.892C11.9213 20.2434 12.0575 20.5691 12.3047 20.8163L13.0916 21.6009C13.339 21.8483 13.6686 21.9831 14.0206 21.9831C14.3722 21.9831 14.7019 21.8465 14.9493 21.5991L23.6166 12.9319C23.8646 12.6837 24.001 12.3524 24 12.0002C24.0008 11.6468 23.8646 11.3153 23.6164 11.0675Z" fill="white"/>
            </svg>
            {{$t('new_activity_next')}}
          </button>
        </div>
      </div>
      <div class="ActivityCreation__Content--Box" v-if="STEP == 2 && activityForm.type == 'EL_HEATMAP'" >
        <HeatMapPointsCreation
          ref="HeatMapPointsCreation"
          class="ActivityCreation__HeatMap"
          :heatmapPointDefinitions="heatmapPointDefinitions"
          :heatMapImages="heatMapImages"
          :uploadHeaders="uploadHeaders"
          :fileUploadPath="fileUploadPath"
          v-on:add="addHeatMapPoint($event)"
          v-on:delete="deleteHeatMapPoint($event)"/>
        <div class="ActivityCreation__Actions">
          <CommunityButton class="ActivityCreation__Actions--Previous" v-on:click="returnStep(1)">{{$t('new_activity_step_back',{step:1})}}</CommunityButton>
          <CommunityButton class="ActivityCreation__Actions--Next" v-on:click="submitStep2HeatMap(null)">{{$t('new_activity_next')}}</CommunityButton>
          <CommunityButton :disabled="!dataIsUpdated" class="ActivityCreation__Actions--Next" v-on:click="submitStep2HeatMap(true)">{{$t('action_save')}}</CommunityButton>
        </div>
        <CommunityErrors :errors="errors" v-show="errors.length > 0"/>
        <div class="ActivityCreation__Options">
          <button class="ActivityCreation__Options--Exit" v-on:click="exit()">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2168 5.21094C15.7206 5.21094 15.3184 4.80871 15.3184 4.3125V1.79688H2.4707V21.2031H15.3184V18.6875C15.3184 18.1913 15.7206 17.7891 16.2168 17.7891C16.713 17.7891 17.1152 18.1913 17.1152 18.6875V22.1016C17.1152 22.5978 16.713 23 16.2168 23H1.57227C1.07606 23 0.673828 22.5978 0.673828 22.1016V0.898438C0.673828 0.402231 1.07606 0 1.57227 0H16.2168C16.713 0 17.1152 0.402231 17.1152 0.898438V4.3125C17.1152 4.80871 16.713 5.21094 16.2168 5.21094Z" fill="#BDBDBD"/>
              <path d="M22.0628 12.1353L19.6487 14.5494C19.2978 14.9003 18.729 14.9003 18.3781 14.5494C18.0272 14.1986 18.0272 13.6297 18.3781 13.2788L19.2585 12.3985L11.8159 12.3985C11.3198 12.3985 10.9175 11.9963 10.9175 11.5001C10.9175 11.0039 11.3197 10.6016 11.8159 10.6016L19.2586 10.6016L18.3781 9.72116C18.0273 9.37036 18.0273 8.80147 18.3781 8.45058C18.729 8.0997 19.2979 8.09979 19.6487 8.45063L22.0628 10.8647C22.0629 10.8648 22.0629 10.8649 22.063 10.865C22.2438 11.0459 22.3194 11.255 22.326 11.5C22.326 11.7422 22.223 11.9751 22.0628 12.1353Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_exit')}}
          </button>
          <button class="ActivityCreation__Options--Back" v-on:click="returnStep(1)">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.383628 10.9325L9.05087 19.5999C9.29828 19.8473 9.62803 19.9832 9.97964 19.9832C10.3316 19.9832 10.6612 19.8472 10.9086 19.5999L11.6955 18.8128C11.9427 18.5658 12.0789 18.2359 12.0789 17.884C12.0789 17.5324 11.9427 17.1914 11.6955 16.9443L6.63918 11.8769L22.7034 11.8769C23.4277 11.8769 24 11.3099 24 10.5854L24 9.47261C24 8.74813 23.4277 8.12393 22.7034 8.12393L6.58182 8.12393L11.6953 3.02817C11.9425 2.78076 12.0787 2.45978 12.0787 2.10798C12.0787 1.75657 11.9425 1.43091 11.6953 1.1837L10.9084 0.399119C10.661 0.151707 10.3314 0.0168788 9.97945 0.0168788C9.62784 0.0168788 9.29809 0.153462 9.05067 0.400874L0.383433 9.06812C0.135436 9.31631 -0.000952771 9.64763 2.3761e-05 9.99982C-0.000758283 10.3532 0.135436 10.6847 0.383628 10.9325Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_back')}}
          </button>
          <button class="ActivityCreation__Options--Next" v-on:click="submitStep2HeatMap()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.6164 11.0675L14.9491 2.40006C14.7017 2.15265 14.372 2.01685 14.0204 2.01685C13.6684 2.01685 13.3388 2.15284 13.0914 2.40006L12.3045 3.18718C12.0573 3.4342 11.9211 3.76415 11.9211 4.11595C11.9211 4.46756 12.0573 4.80863 12.3045 5.05565L17.3608 10.1231H1.29657C0.572287 10.1231 0 10.6901 0 11.4146V12.5274C0 13.2519 0.572287 13.8761 1.29657 13.8761H17.4182L12.3047 18.9718C12.0575 19.2192 11.9213 19.5402 11.9213 19.892C11.9213 20.2434 12.0575 20.5691 12.3047 20.8163L13.0916 21.6009C13.339 21.8483 13.6686 21.9831 14.0206 21.9831C14.3722 21.9831 14.7019 21.8465 14.9493 21.5991L23.6166 12.9319C23.8646 12.6837 24.001 12.3524 24 12.0002C24.0008 11.6468 23.8646 11.3153 23.6164 11.0675Z" fill="white"/>
            </svg>
            {{$t('new_activity_next')}}
          </button>
        </div>
      </div>
      <div class="ActivityCreation__Content--QuestionBox" v-if="STEP == 2 && isQuestionsActivity() && questions.length>0">
        <draggable class="ActivityCreation__QuestionDrag" v-model="questions" item-key="order" handle=".icon-move-icon" v-bind="dragOptions" @end="reorderQuestions()">
          <template #item="{element,index}">
              <QuestionSurvey
                class="ActivityCreation__Question"
                :question="element"
                :questionList="questions"
                :rules="rules"
                :ref="'QuestionEdit-'+element.id"
                :isPollActivity="activityForm.type=='WAT_POLL'"
                v-on:add-value="addValue(element, false)"
                v-on:add-txt="addValue(element, true)"
                v-on:add-option="addOptionMatrix(element)"
                v-on:duplicate-question="duplicateQuestion(element)"
                v-on:delete-question="canRemoveQuestion(index)"
                v-on:delete-value="removeQuestionValue($event, index)"
                v-on:delete-value-option="removeQuestionValueOption($event, index)"
                v-on:data-updated="changesDetected()"
                v-on:change-rule="changesDetected()"
                v-on:add-rule="addRule(element)"/>
          </template>
        </draggable>
      </div>
      <div class="ActivityCreation__Content--Box" :class="{'withQuestions':questions.length>0}" v-if="STEP == 2 && isQuestionsActivity()">
        <QuestionCreation
          class="ActivityCreation__Survey"
          v-on:add="createQuestion($event)"
          :id="'QuestionSurveyCreation'"
          :questions="questions"
          :isPollActivity="activityForm.type=='WAT_POLL'" />
        <div class="ActivityCreation__Actions">
          <CommunityButton class="ActivityCreation__Actions--Previous" v-on:click="returnStep(1)">{{$t('new_activity_step_back',{step:1})}}</CommunityButton>
          <CommunityButton class="ActivityCreation__Actions--Next" v-on:click="submitStep2Survey(null)">{{$t('new_activity_next')}}</CommunityButton>
          <CommunityButton :disabled="!dataIsUpdated" class="ActivityCreation__Actions--Next" v-on:click="submitStep2Survey(true)">{{$t('action_save')}}</CommunityButton>
        </div>
        <CommunityErrors :errors="errors" v-show="errors.length > 0"/>
        <div class="ActivityCreation__Options">
          <button class="ActivityCreation__Options--Exit" v-on:click="exit()">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2168 5.21094C15.7206 5.21094 15.3184 4.80871 15.3184 4.3125V1.79688H2.4707V21.2031H15.3184V18.6875C15.3184 18.1913 15.7206 17.7891 16.2168 17.7891C16.713 17.7891 17.1152 18.1913 17.1152 18.6875V22.1016C17.1152 22.5978 16.713 23 16.2168 23H1.57227C1.07606 23 0.673828 22.5978 0.673828 22.1016V0.898438C0.673828 0.402231 1.07606 0 1.57227 0H16.2168C16.713 0 17.1152 0.402231 17.1152 0.898438V4.3125C17.1152 4.80871 16.713 5.21094 16.2168 5.21094Z" fill="#BDBDBD"/>
              <path d="M22.0628 12.1353L19.6487 14.5494C19.2978 14.9003 18.729 14.9003 18.3781 14.5494C18.0272 14.1986 18.0272 13.6297 18.3781 13.2788L19.2585 12.3985L11.8159 12.3985C11.3198 12.3985 10.9175 11.9963 10.9175 11.5001C10.9175 11.0039 11.3197 10.6016 11.8159 10.6016L19.2586 10.6016L18.3781 9.72116C18.0273 9.37036 18.0273 8.80147 18.3781 8.45058C18.729 8.0997 19.2979 8.09979 19.6487 8.45063L22.0628 10.8647C22.0629 10.8648 22.0629 10.8649 22.063 10.865C22.2438 11.0459 22.3194 11.255 22.326 11.5C22.326 11.7422 22.223 11.9751 22.0628 12.1353Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_exit')}}
          </button>
          <button class="ActivityCreation__Options--Logic" v-on:click="showGeneralRules = true" v-show="activityForm.type == 'WAT_SURVEY'">
            <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28 13.7792H5.09091V8.25491H28V13.7792ZM22.9091 5.95225H0V0.324677H22.9091V5.95225Z" fill="url(#paint0_linear)"/>
              <path d="M22.9091 21.4156H0V15.961H22.9091V21.4156Z" fill="url(#paint1_linear)"/>
              <path d="M1.09091 8.32466H0V11.5974H1.09091V8.32466Z" fill="url(#paint2_linear)"/>
              <path d="M4 11.5974V10.5065H0.363637V11.5974H4Z" fill="url(#paint3_linear)"/>
              <defs>
              <linearGradient id="paint0_linear" x1="1.04308e-07" y1="7.05195" x2="28" y2="7.05195" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              <linearGradient id="paint1_linear" x1="8.5343e-08" y1="18.6883" x2="22.9091" y2="18.6883" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              <linearGradient id="paint2_linear" x1="4.06395e-09" y1="9.96102" x2="1.09091" y2="9.96102" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              <linearGradient id="paint3_linear" x1="2.18182" y1="10.5065" x2="2.18182" y2="11.5974" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              </defs>
            </svg>
            {{$t('new_activity_survey_logic')}}
          </button>
          <button v-on:click="openPreview()" v-show="activityForm.type == 'WAT_SURVEY'">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.84785 5.99849C6.92452 2.73766 10.344 0.791656 13.9992 0.791656C17.6544 0.791656 21.0739 2.73766 23.1505 5.99849C23.3932 6.38116 23.3932 6.86882 23.1505 7.25149C21.0739 10.5123 17.6544 12.4583 13.9992 12.4583C10.344 12.4583 6.92452 10.5123 4.84785 7.25149C4.60518 6.86882 4.60518 6.38116 4.84785 5.99849ZM13.9992 8.95832C15.2884 8.95832 16.3325 7.91416 16.3325 6.62499C16.3325 5.33582 15.2884 4.29166 13.9992 4.29166C12.71 4.29166 11.6659 5.33582 11.6659 6.62499C11.6659 7.91416 12.71 8.95832 13.9992 8.95832Z" fill="url(#paint0_linear)"/>
              <path d="M1.16667 4.29166C1.81183 4.29166 2.33333 4.81316 2.33333 5.45832V20.625H25.6667V5.45832C25.6667 4.81316 26.1882 4.29166 26.8333 4.29166C27.4785 4.29166 28 4.81316 28 5.45832V20.625C28 21.9118 26.9535 22.9583 25.6667 22.9583H16.3333V25.2917H18.9583C19.6035 25.2917 20.125 25.8132 20.125 26.4583C20.125 27.1035 19.6035 27.625 18.9583 27.625H9.04167C8.3965 27.625 7.875 27.1035 7.875 26.4583C7.875 25.8132 8.3965 25.2917 9.04167 25.2917H11.6667V22.9583H2.33333C1.0465 22.9583 0 21.9118 0 20.625V5.45832C0 4.81316 0.521502 4.29166 1.16667 4.29166Z" fill="url(#paint1_linear)"/>
              <defs>
              <linearGradient id="paint0_linear" x1="23.3325" y1="6.62499" x2="4.66585" y2="6.62499" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              <linearGradient id="paint1_linear" x1="28" y1="15.9583" x2="-1.04308e-07" y2="15.9583" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              </defs>
            </svg>
            {{$t('new_activity_survey_preview')}}
          </button>
          <button class="ActivityCreation__Options--Back" v-on:click="returnStep(1)">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.383628 10.9325L9.05087 19.5999C9.29828 19.8473 9.62803 19.9832 9.97964 19.9832C10.3316 19.9832 10.6612 19.8472 10.9086 19.5999L11.6955 18.8128C11.9427 18.5658 12.0789 18.2359 12.0789 17.884C12.0789 17.5324 11.9427 17.1914 11.6955 16.9443L6.63918 11.8769L22.7034 11.8769C23.4277 11.8769 24 11.3099 24 10.5854L24 9.47261C24 8.74813 23.4277 8.12393 22.7034 8.12393L6.58182 8.12393L11.6953 3.02817C11.9425 2.78076 12.0787 2.45978 12.0787 2.10798C12.0787 1.75657 11.9425 1.43091 11.6953 1.1837L10.9084 0.399119C10.661 0.151707 10.3314 0.0168788 9.97945 0.0168788C9.62784 0.0168788 9.29809 0.153462 9.05067 0.400874L0.383433 9.06812C0.135436 9.31631 -0.000952771 9.64763 2.3761e-05 9.99982C-0.000758283 10.3532 0.135436 10.6847 0.383628 10.9325Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_back')}}
          </button>
          <button class="ActivityCreation__Options--Next" v-on:click="submitStep2Survey()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.6164 11.0675L14.9491 2.40006C14.7017 2.15265 14.372 2.01685 14.0204 2.01685C13.6684 2.01685 13.3388 2.15284 13.0914 2.40006L12.3045 3.18718C12.0573 3.4342 11.9211 3.76415 11.9211 4.11595C11.9211 4.46756 12.0573 4.80863 12.3045 5.05565L17.3608 10.1231H1.29657C0.572287 10.1231 0 10.6901 0 11.4146V12.5274C0 13.2519 0.572287 13.8761 1.29657 13.8761H17.4182L12.3047 18.9718C12.0575 19.2192 11.9213 19.5402 11.9213 19.892C11.9213 20.2434 12.0575 20.5691 12.3047 20.8163L13.0916 21.6009C13.339 21.8483 13.6686 21.9831 14.0206 21.9831C14.3722 21.9831 14.7019 21.8465 14.9493 21.5991L23.6166 12.9319C23.8646 12.6837 24.001 12.3524 24 12.0002C24.0008 11.6468 23.8646 11.3153 23.6164 11.0675Z" fill="white"/>
            </svg>
            {{$t('new_activity_next')}}
          </button>
        </div>
        <ModalFullScreen class="GeneralRules" v-if="showGeneralRules" v-on:close="showGeneralRules = false" :style="{'z-index':'100'}">
          <NavigationRules ref="navigationRules" :rules="rules" :questionList="questions" v-on:add-rule="addRule(questions[0])" v-on:change-rule="changesDetected()"/>
          <CommunityButton :disabled="!dataIsUpdated" v-on:click="submitStep2Survey(true)">{{$t('action_save')}}</CommunityButton>
        </ModalFullScreen>
        <ModalFullScreen class="ExecutionPreview" v-if="showPreview" v-on:close="showPreview = false">
          <SurveyExecution v-on:finished="showPreview = false"/>
        </ModalFullScreen>
      </div>
      <div class="ActivityCreation__Content--Box" v-if="STEP == 2 && activityForm.type == 'WAT_UX'" >
        <WatUxDataCreation ref="WatUxDataCreation" class="ActivityCreation__WatUx" :uxData="uxData" :checkpoints="checkpoints" v-on:add="addCheckpoint($event)" v-on:delete="deleteCheckpoint($event)"/>
        <div class="ActivityCreation__Actions">
          <CommunityButton class="ActivityCreation__Actions--Previous" v-on:click="returnStep(1)">{{$t('new_activity_step_back',{step:1})}}</CommunityButton>
          <CommunityButton class="ActivityCreation__Actions--Next" v-on:click="submitStep2WatUx(null)">{{$t('new_activity_next')}}</CommunityButton>
          <CommunityButton :disabled="!dataIsUpdated" class="ActivityCreation__Actions--Next" v-on:click="submitStep2WatUx(true)">{{$t('action_save')}}</CommunityButton>
        </div>
        <CommunityErrors :errors="errors" v-show="errors.length > 0"/>
        <div class="ActivityCreation__Options">
          <button class="ActivityCreation__Options--Exit" v-on:click="exit()">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2168 5.21094C15.7206 5.21094 15.3184 4.80871 15.3184 4.3125V1.79688H2.4707V21.2031H15.3184V18.6875C15.3184 18.1913 15.7206 17.7891 16.2168 17.7891C16.713 17.7891 17.1152 18.1913 17.1152 18.6875V22.1016C17.1152 22.5978 16.713 23 16.2168 23H1.57227C1.07606 23 0.673828 22.5978 0.673828 22.1016V0.898438C0.673828 0.402231 1.07606 0 1.57227 0H16.2168C16.713 0 17.1152 0.402231 17.1152 0.898438V4.3125C17.1152 4.80871 16.713 5.21094 16.2168 5.21094Z" fill="#BDBDBD"/>
              <path d="M22.0628 12.1353L19.6487 14.5494C19.2978 14.9003 18.729 14.9003 18.3781 14.5494C18.0272 14.1986 18.0272 13.6297 18.3781 13.2788L19.2585 12.3985L11.8159 12.3985C11.3198 12.3985 10.9175 11.9963 10.9175 11.5001C10.9175 11.0039 11.3197 10.6016 11.8159 10.6016L19.2586 10.6016L18.3781 9.72116C18.0273 9.37036 18.0273 8.80147 18.3781 8.45058C18.729 8.0997 19.2979 8.09979 19.6487 8.45063L22.0628 10.8647C22.0629 10.8648 22.0629 10.8649 22.063 10.865C22.2438 11.0459 22.3194 11.255 22.326 11.5C22.326 11.7422 22.223 11.9751 22.0628 12.1353Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_exit')}}
          </button>
          <button class="ActivityCreation__Options--Back" v-on:click="returnStep(1)">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.383628 10.9325L9.05087 19.5999C9.29828 19.8473 9.62803 19.9832 9.97964 19.9832C10.3316 19.9832 10.6612 19.8472 10.9086 19.5999L11.6955 18.8128C11.9427 18.5658 12.0789 18.2359 12.0789 17.884C12.0789 17.5324 11.9427 17.1914 11.6955 16.9443L6.63918 11.8769L22.7034 11.8769C23.4277 11.8769 24 11.3099 24 10.5854L24 9.47261C24 8.74813 23.4277 8.12393 22.7034 8.12393L6.58182 8.12393L11.6953 3.02817C11.9425 2.78076 12.0787 2.45978 12.0787 2.10798C12.0787 1.75657 11.9425 1.43091 11.6953 1.1837L10.9084 0.399119C10.661 0.151707 10.3314 0.0168788 9.97945 0.0168788C9.62784 0.0168788 9.29809 0.153462 9.05067 0.400874L0.383433 9.06812C0.135436 9.31631 -0.000952771 9.64763 2.3761e-05 9.99982C-0.000758283 10.3532 0.135436 10.6847 0.383628 10.9325Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_back')}}
          </button>
          <button class="ActivityCreation__Options--Next" v-on:click="submitStep2WatUx()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.6164 11.0675L14.9491 2.40006C14.7017 2.15265 14.372 2.01685 14.0204 2.01685C13.6684 2.01685 13.3388 2.15284 13.0914 2.40006L12.3045 3.18718C12.0573 3.4342 11.9211 3.76415 11.9211 4.11595C11.9211 4.46756 12.0573 4.80863 12.3045 5.05565L17.3608 10.1231H1.29657C0.572287 10.1231 0 10.6901 0 11.4146V12.5274C0 13.2519 0.572287 13.8761 1.29657 13.8761H17.4182L12.3047 18.9718C12.0575 19.2192 11.9213 19.5402 11.9213 19.892C11.9213 20.2434 12.0575 20.5691 12.3047 20.8163L13.0916 21.6009C13.339 21.8483 13.6686 21.9831 14.0206 21.9831C14.3722 21.9831 14.7019 21.8465 14.9493 21.5991L23.6166 12.9319C23.8646 12.6837 24.001 12.3524 24 12.0002C24.0008 11.6468 23.8646 11.3153 23.6164 11.0675Z" fill="white"/>
            </svg>
            {{$t('new_activity_next')}}
          </button>
        </div>
      </div>
      <div class="ActivityCreation__Content--Box" v-if="STEP == 3">
        <div class="ActivityCreation__Step3">
          <div class="ActivityCreation__Visibility">
            <h1>{{$t('new_activity_visibility_title')}}</h1>
            <div class="ActivityCreation__Visibility--Types">
              <BoxShadow v-on:click.native="activityForm.publicActivity = true;dataIsUpdated = true" :class="{'active':activityForm.publicActivity}" class="enableCheck">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29.8889 14.645C29.6526 14.3012 23.975 6.24982 14.9999 6.24982C7.29854 6.24982 0.43593 14.255 0.147175 14.5962C-0.0490584 14.8287 -0.0490584 15.17 0.147175 15.4037C0.43593 15.745 7.29854 23.7501 14.9999 23.7501C22.7013 23.7501 29.5639 15.745 29.8526 15.4037C30.0339 15.1887 30.0501 14.8775 29.8889 14.645ZM14.9999 22.5001C8.8261 22.5001 2.95597 16.6125 1.46473 15C2.95351 13.3862 8.81737 7.49987 14.9999 7.49987C22.2238 7.49987 27.3226 13.3787 28.5676 14.9662C27.1301 16.5275 21.2263 22.5001 14.9999 22.5001Z" fill="#96C800"/>
                  <path d="M15.0001 9.99988C12.2425 9.99988 10 12.2424 10 14.9999C10 17.7575 12.2425 20 15.0001 20C17.7576 20 20.0001 17.7575 20.0001 14.9999C20.0001 12.2424 17.7576 9.99988 15.0001 9.99988ZM15.0001 18.7501C12.9325 18.7501 11.25 17.0675 11.25 15C11.25 12.9325 12.9325 11.2499 15.0001 11.2499C17.0676 11.2499 18.7501 12.9325 18.7501 15C18.7501 17.0675 17.0676 18.7501 15.0001 18.7501Z" fill="#96C800"/>
                </svg>
                <div>
                  <h1>{{$t('new_activity_type_public')}}</h1>
                  <p>{{$t('new_activity_type_public_info')}}</p>
                  <p v-if="activityForm.type == 'VIDEOCHAT'">{{$t('new_activity_type_public_info_videochat')}}</p>
                </div>
              </BoxShadow>
              <BoxShadow v-on:click.native="activityForm.publicActivity = false;dataIsUpdated = true" :class="{'active':!activityForm.publicActivity}" class="enableCheck">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                  <path d="M18.5351 11.4646C18.2913 11.2208 17.8951 11.2208 17.6513 11.4646C17.4076 11.7083 17.4076 12.1058 17.6513 12.3483C18.3588 13.0558 18.7501 13.9971 18.7501 14.9996C18.7501 17.067 17.0675 18.7496 15.0001 18.7496C13.9976 18.7496 13.0563 18.3596 12.3488 17.6508C12.1051 17.4071 11.7088 17.4071 11.465 17.6508C11.2213 17.8933 11.2213 18.2908 11.465 18.5346C12.4075 19.4796 13.6638 19.9996 15.0001 19.9996C17.7575 19.9996 20 17.7571 20 14.9996C20 13.6633 19.4801 12.407 18.5351 11.4646Z" fill="#96C800"/>
                  <path d="M15.8712 10.0858C15.5874 10.0358 15.2975 9.99951 15 9.99951C12.2425 9.99951 10 12.242 10 14.9995C10 15.297 10.0363 15.587 10.0875 15.8707C10.1412 16.1732 10.405 16.387 10.7013 16.387C10.7375 16.387 10.7737 16.3845 10.8112 16.377C11.15 16.317 11.3775 15.9919 11.3175 15.6532C11.2788 15.4407 11.25 15.2245 11.25 14.9995C11.25 12.932 12.9325 11.2495 15 11.2495C15.225 11.2495 15.4413 11.2783 15.6537 11.3158C15.9862 11.3833 16.3175 11.1482 16.3775 10.8095C16.4375 10.4708 16.21 10.1458 15.8712 10.0858Z" fill="#96C800"/>
                  <path d="M29.8511 14.5958C29.7099 14.4283 26.3287 10.4821 21.6649 8.06585C21.3624 7.90712 20.9812 8.02712 20.8224 8.33462C20.6636 8.64089 20.7836 9.01835 21.0911 9.17714C24.6836 11.0371 27.5536 13.9396 28.5349 14.9996C27.0449 16.6134 21.1811 22.4996 14.9999 22.4996C12.9024 22.4996 10.7887 21.9934 8.71494 20.9934C8.40744 20.8421 8.03121 20.9734 7.88121 21.2846C7.72998 21.5947 7.86123 21.9684 8.17248 22.1184C10.4162 23.2021 12.7137 23.7496 15 23.7496C22.7012 23.7496 29.5637 15.7446 29.8525 15.4034C30.0499 15.1708 30.0486 14.8296 29.8511 14.5958Z" fill="#96C800"/>
                  <path d="M19.1013 6.96951C17.6788 6.49074 16.2988 6.24951 15.0001 6.24951C7.2988 6.24951 0.436315 14.2545 0.147565 14.5958C-0.034954 14.8107 -0.049954 15.1233 0.112585 15.357C0.198835 15.4808 2.26508 18.4145 5.84632 20.7933C5.95255 20.8645 6.07132 20.8983 6.19132 20.8983C6.39259 20.8983 6.59134 20.8008 6.71134 20.617C6.90259 20.3308 6.82384 19.942 6.53632 19.752C3.90635 18.0033 2.10635 15.892 1.43258 15.0333C2.87135 13.4708 8.77384 7.49955 15.0001 7.49955C16.1638 7.49955 17.4101 7.71957 18.7025 8.15328C19.03 8.27076 19.385 8.08953 19.4938 7.76076C19.6038 7.43328 19.4288 7.07955 19.1013 6.96951Z" fill="#96C800"/>
                  <path d="M26.066 3.9333C25.8223 3.68955 25.426 3.68955 25.1822 3.9333L3.93232 25.1833C3.68857 25.427 3.68857 25.8233 3.93232 26.067C4.05484 26.1883 4.2148 26.2496 4.37482 26.2496C4.53484 26.2496 4.6948 26.1883 4.81609 26.067L26.066 4.81707C26.3098 4.57332 26.3098 4.17705 26.066 3.9333Z" fill="#96C800"/>
                  </g>
                  <defs>
                  <clipPath id="clip0">
                  <rect width="30" height="30" fill="#96C800"/>
                  </clipPath>
                  </defs>
                </svg>
                <div>
                  <h1>{{$t('new_activity_type_private')}}</h1>
                  <p>{{$t('new_activity_type_private_info')}}</p>
                </div>
              </BoxShadow>
            </div>
          </div>
          <div class="ActivityCreation__VideoChatPublic" v-show="activityForm.type == 'VIDEOCHAT'">
            <div v-show="activityForm.publicActivity">
              <h1>{{$t('new_activity_participants_num')}}</h1>
              <CommunityInput ref="participants" type="number" :max="10" :required="true" placeholder="0" v-model="activityForm.videochatPublicParticipantsNum" v-on:key-up="dataIsUpdated = true"/>
            </div>
            <div>
              <h1>{{$t('new_activity_password')}}</h1>
              <CommunityInput ref="password" :minLength="10" type="password" :required="true" :placeholder="$t('password')" :passErrorMessage="$t('error_new_password_not_valid',{simbols:'(@ $ ! % * ? &)'})" v-model="activityForm.videochatPublicPassword" v-on:key-up="dataIsUpdated = true"/>
            </div>
          </div>
          <div class="ActivityCreation__VideoChatPublic" v-show="activityForm.type == 'VIDEOCHAT'">
            <div>
              <h1>{{$t('new_activity_share_screen')}}</h1>
              <SwitchInput v-model="activityForm.videochatUserShareScreen"/>
            </div>
          </div>
          <div class="ActivityCreation__Members" :class="{'opacity':activityForm.publicActivity}">
            <CommunityUserAssignment
              class="UserAssignment"
              :members="members"
              :tags="tags"
              v-on:filter-all="fetchMembers($event,'filtered')"
              v-on:filter-added="fetchMembers($event,'filteredAdded')"/>
          </div>
        </div>
        <div class="ActivityCreation__Actions">
          <CommunityButton class="ActivityCreation__Actions--Previous" v-on:click="returnStep(2)">{{$t('new_activity_step_back',{step:2})}}</CommunityButton>
          <CommunityButton class="ActivityCreation__Actions--Next" v-on:click="submitStep3(null)">{{$t('new_activity_publish')}}</CommunityButton>
        </div>
        <CommunityErrors :errors="errors" v-show="errors.length > 0"/>
        <div class="ActivityCreation__Options">
          <button class="ActivityCreation__Options--Exit" v-on:click="exit()">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2168 5.21094C15.7206 5.21094 15.3184 4.80871 15.3184 4.3125V1.79688H2.4707V21.2031H15.3184V18.6875C15.3184 18.1913 15.7206 17.7891 16.2168 17.7891C16.713 17.7891 17.1152 18.1913 17.1152 18.6875V22.1016C17.1152 22.5978 16.713 23 16.2168 23H1.57227C1.07606 23 0.673828 22.5978 0.673828 22.1016V0.898438C0.673828 0.402231 1.07606 0 1.57227 0H16.2168C16.713 0 17.1152 0.402231 17.1152 0.898438V4.3125C17.1152 4.80871 16.713 5.21094 16.2168 5.21094Z" fill="#BDBDBD"/>
              <path d="M22.0628 12.1353L19.6487 14.5494C19.2978 14.9003 18.729 14.9003 18.3781 14.5494C18.0272 14.1986 18.0272 13.6297 18.3781 13.2788L19.2585 12.3985L11.8159 12.3985C11.3198 12.3985 10.9175 11.9963 10.9175 11.5001C10.9175 11.0039 11.3197 10.6016 11.8159 10.6016L19.2586 10.6016L18.3781 9.72116C18.0273 9.37036 18.0273 8.80147 18.3781 8.45058C18.729 8.0997 19.2979 8.09979 19.6487 8.45063L22.0628 10.8647C22.0629 10.8648 22.0629 10.8649 22.063 10.865C22.2438 11.0459 22.3194 11.255 22.326 11.5C22.326 11.7422 22.223 11.9751 22.0628 12.1353Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_exit')}}
          </button>
          <button class="ActivityCreation__Options--Logic" v-on:click="showGeneralRules = true" v-if="activityForm.type == 'WAT_SURVEY'">
            <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28 13.7792H5.09091V8.25491H28V13.7792ZM22.9091 5.95225H0V0.324677H22.9091V5.95225Z" fill="url(#paint0_linear)"/>
              <path d="M22.9091 21.4156H0V15.961H22.9091V21.4156Z" fill="url(#paint1_linear)"/>
              <path d="M1.09091 8.32466H0V11.5974H1.09091V8.32466Z" fill="url(#paint2_linear)"/>
              <path d="M4 11.5974V10.5065H0.363637V11.5974H4Z" fill="url(#paint3_linear)"/>
              <defs>
              <linearGradient id="paint0_linear" x1="1.04308e-07" y1="7.05195" x2="28" y2="7.05195" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              <linearGradient id="paint1_linear" x1="8.5343e-08" y1="18.6883" x2="22.9091" y2="18.6883" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              <linearGradient id="paint2_linear" x1="4.06395e-09" y1="9.96102" x2="1.09091" y2="9.96102" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              <linearGradient id="paint3_linear" x1="2.18182" y1="10.5065" x2="2.18182" y2="11.5974" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              </defs>
            </svg>
            {{$t('new_activity_survey_logic')}}
          </button>
          <button class="ActivityCreation__Options--Back" v-on:click="returnStep(2)">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.383628 10.9325L9.05087 19.5999C9.29828 19.8473 9.62803 19.9832 9.97964 19.9832C10.3316 19.9832 10.6612 19.8472 10.9086 19.5999L11.6955 18.8128C11.9427 18.5658 12.0789 18.2359 12.0789 17.884C12.0789 17.5324 11.9427 17.1914 11.6955 16.9443L6.63918 11.8769L22.7034 11.8769C23.4277 11.8769 24 11.3099 24 10.5854L24 9.47261C24 8.74813 23.4277 8.12393 22.7034 8.12393L6.58182 8.12393L11.6953 3.02817C11.9425 2.78076 12.0787 2.45978 12.0787 2.10798C12.0787 1.75657 11.9425 1.43091 11.6953 1.1837L10.9084 0.399119C10.661 0.151707 10.3314 0.0168788 9.97945 0.0168788C9.62784 0.0168788 9.29809 0.153462 9.05067 0.400874L0.383433 9.06812C0.135436 9.31631 -0.000952771 9.64763 2.3761e-05 9.99982C-0.000758283 10.3532 0.135436 10.6847 0.383628 10.9325Z" fill="#BDBDBD"/>
            </svg>
            {{$t('new_activity_back')}}
          </button>
          <button class="ActivityCreation__Options--Next" v-on:click="submitStep3()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.6164 11.0675L14.9491 2.40006C14.7017 2.15265 14.372 2.01685 14.0204 2.01685C13.6684 2.01685 13.3388 2.15284 13.0914 2.40006L12.3045 3.18718C12.0573 3.4342 11.9211 3.76415 11.9211 4.11595C11.9211 4.46756 12.0573 4.80863 12.3045 5.05565L17.3608 10.1231H1.29657C0.572287 10.1231 0 10.6901 0 11.4146V12.5274C0 13.2519 0.572287 13.8761 1.29657 13.8761H17.4182L12.3047 18.9718C12.0575 19.2192 11.9213 19.5402 11.9213 19.892C11.9213 20.2434 12.0575 20.5691 12.3047 20.8163L13.0916 21.6009C13.339 21.8483 13.6686 21.9831 14.0206 21.9831C14.3722 21.9831 14.7019 21.8465 14.9493 21.5991L23.6166 12.9319C23.8646 12.6837 24.001 12.3524 24 12.0002C24.0008 11.6468 23.8646 11.3153 23.6164 11.0675Z" fill="white"/>
            </svg>
            {{$t('new_activity_publish')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import store from '@/store';
import ApiService from '~/services/api.service';
import {COMMUNITY_FETCH_TAGS} from '@/store/community/tags.module';
import { COMMUNITY_FETCH_MEMBERS } from '@/store/community/members.module';
import {
  ACTIVITY_STEP_1,
  ACTIVITY_STEP_2_TOPICS,
  ACTIVITY_STEP_2_HEATMAP,
  ACTIVITY_STEP_2_SURVEY,
  ACTIVITY_STEP_2_WATUX,
  ACTIVITY_STEP_3,
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL,
  ACTIVITY_NAVIGATION_RULES,
  COMMUNITY_FETCH_ACTIVITY,
  COMMUNITY_ADD_ACTIVITY_QUESTIONS,
  COMMUNITY_UPDATE_ACTIVITY_QUESTIONS,
  COMMUNITY_FETCH_ACTIVITIES,
  COMMUNITY_FETCH_ALLOWED_ACTIVITIES,
} from '@/store/community/activities.module';
import MemberItem from '@/components/MemberItem.vue';
import Dropzone from '~/components/Dropzone';
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import Spinner from '~/components/Spinner.vue';
import Tag from '~/components/Tag';
import Button from '~/components/Button';
import BoxShadow from '@/components/BoxShadow.vue'
import CommunityUserAssignment from '@/components/CommunityUserAssignment.vue'
import CommunityButton from '@/components/CommunityButton'
import TopicsCreation from '@/components/Activity/TopicsCreation'
import HeatMapPointsCreation from '@/components/Activity/HeatMapPointsCreation'
import QuestionCreation from '@/components/Activity/QuestionCreation'
import QuestionEdit from '@/components/Activity/QuestionEdit'
import QuestionSurvey from '~/components/SurveyCreation/QuestionSurvey.vue';
import WatUxDataCreation from '@/components/Activity/WatUxDataCreation'
import CommunityInput from '@/components/CommunityInput.vue';
import ModalFullScreen from '~/components/ModalFullScreen/ModalFullScreen.vue';
import NavigationRules from '~/components/SurveyCreation/NavigationRules.vue';
import CommunityErrors from '@/components/CommunityErrors.vue'
import SurveyExecution from '@/views/SurveyExecution.vue'
import { isEmptyText, sameDayDates, isYouTubeVideo } from '@/utils';
import draggable from 'vuedraggable'
import SwitchInput from '~/components/SwitchInput/SwitchInput'
import { formatNavigationRules,
        navigationRulesHasErrors,
        addRule,
        generateNavigationRules,
        createQuestion,
        duplicateQuestion,
        addOptionMatrix,
        addValue,
        reorderQuestions,
        canRemoveQuestion,
        removeQuestionValueOption,
      removeQuestionValue} from '~/utils/survey-creation.ts'

export default {
  name: 'AddActivityPage',

  components: {
    Dropzone,
    MemberItem,
    Tag,
    Button,
    SearchBar,
    Spinner,
    TopicsCreation,
    HeatMapPointsCreation,
    QuestionCreation,
    QuestionEdit,
    QuestionSurvey,
    WatUxDataCreation,
    CommunityInput,
    ModalFullScreen,
    NavigationRules,
    BoxShadow,
    CommunityUserAssignment,
    CommunityButton,
    CommunityErrors,
    SurveyExecution,
    draggable,
    SwitchInput
  },

  computed: {
    ...mapGetters([
      'communityInfo',
      'communityMembersCount',
      'communityCurrentActivityId',
      'communityActivities',
      'communityMembers',
      'communityTags',
      'communityPublicTags',
      'theme',
      'origin',
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  props:{
    STEP:Number,
    stepType:String,
  },
  data() {
    return {
      activityForm:{
         title:'',
         description:'',
         type:'',
         dateFrom:'',
         dateTo:'',
         publicActivity:false,
         videochatPublicPassword:'',
         videochatPublicParticipantsNum:'',
         videochatUserShareScreen: false,
         activityParticipationLabs: 0,
         activityRaffleData:{
          raffleEnabled: false
         },
         muteNotifications: false
      },
      images:[],
      videos:[],
      files:[],
      debateTopics:[],
      debateTopicsMinForParticipation: 0,
      heatmapPointDefinitions:[],
      heatMapImages:[],
      questions:[],
      rules:[],
      uxData:{
        initialUrl:'',
        recordScreen:false,
        recordMicrophone:false,
        recordWebcam:false,
      },
      checkpoints:[],
      errors:[],
      members:{
        all:[],
        filtered:[],
        filteredAdded:[],
        added:[]
      },
      addedMembers:[],
      tags:[],
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'header': 1 }, { 'header': 2 }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'formula'],
        [{ 'direction': 'rtl' }],
        ['clean'],
      ],
      fileUploadPath: process.env.VUE_APP_API_URL + '/experienceLabs/file',
      videoUploadPath: process.env.VUE_APP_API_URL + '/experienceLabs/video',
      uploadHeaders: ApiService.getConfig().headers,
      showGeneralRules:false,
      showPreview:false,
      dataIsUpdated:false,
      drag:false,
      headerImageKey:0,
      allowedActivities: [],
      showStep3Spinner: false,
      currentRequest: null
    }
  },
  beforeRouteLeave (to, from, next) {
    if(!this.dataIsUpdated || to.name == 'activities.add' || to.name == 'activities.edit' || to.name == 'activities.step2' || to.name == 'activities.step3'){
      next()
      return
    }
    let vm = this

    Swal.fire({
      title: vm.$t('msg_confirmation_exit'),
      text: vm.$t('msg_confirmation_exit_lost'),
      icon: 'warning',
      cancelButtonText: vm.$t('action_cancel'),
      confirmButtonText: vm.$t('msg_agree'),
      showCancelButton: true,
      customClass:{
        popup:'swal-customWarning'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        next();
      }
    })
  },
  async mounted() {
        let promises = [
            this.fetchAllowedActivities(),
            this.fetchTags(),
            this.fetchMembers(null, null)
        ];

      if (this.stepType == "EDIT") {
          await this.fetchActivity();

          let editPromises = [];

          if (this.activityForm.type == "WAT_SURVEY") {
              editPromises.push(this.fetchNavigationRules());
          }
          if (this.activityForm.type == "WAT_POLL") {
              this.generatePollQuestion();
          }
          editPromises.push(this.fetchParticipants());

          await Promise.all(editPromises);
      }

        await Promise.all(promises);
    },
  methods: {
    async fetchAllowedActivities(){
      await store.dispatch(COMMUNITY_FETCH_ALLOWED_ACTIVITIES)
      .then((data) => {
        this.allowedActivities = data
      })
    },
    openPreview(){
      if(this.dataIsUpdated){
        Swal.fire({
          text:this.$t('msg_new_activity_open_preview'),
          icon:"warning",
          customClass:{popup:"swal-customWarning"}
        })
        return
      }
      this.showPreview = true
    },
    changeActivityType(type){
      if(this.activityForm.identifier) return
      this.activityForm.type = type
    },
    async fetchTags(){
      await store.dispatch(COMMUNITY_FETCH_TAGS)
      .then((data) => {
        if(data.publicTags == undefined && data.tags == undefined) this.tags = []
        else if(data.publicTags == undefined) this.tags = data.tags
        else if(data.tags == undefined) this.tags = data.publicTags
        else{
          this.tags = data.publicTags.concat(data.tags)
        }
      })
    },
    returnStep(step){
      if(!this.isQuestionsActivity() && this.activityForm.type != 'WAT_CHAT' && this.activityForm.type != 'WAT_UX') step = 1
      if(step == 1)  this.$router.push({ name: 'activities.edit', params: { id: this.$route.params.id } })
      if(step == 2)  this.$router.push({ name: 'activities.step2', params: { id: this.$route.params.id } })
    },
    async submitStep1(save){
      this.errors = []
      if (this.$refs.title.validate() != false) this.errors.push(this.$t('error_empty_title'))
      if (this.$refs.description.validate() !=false) this.errors.push(this.$t('error_empty_description'))
      if (isEmptyText(this.activityForm.type)) this.errors.push(this.$t('error_activity_type_not_selected'))
      if (this.checkDateErrors()) this.errors.push(this.$t('error_incorrect_dates'))
      if (isYouTubeVideo(this.activityForm.isYouTubeVideo)) this.erros.push(this.$t('validation_youtubelink'))
      if(this.errors.length > 0 ) return
      let data = {
        activityRaffleData: this.activityForm.activityRaffleData,
        headerImage: this.activityForm.headerImage,
        activityId:this.activityForm.identifier,
        title:this.activityForm.title,
        description:this.activityForm.description,
        type:this.activityForm.type,
        dateFrom:moment(this.activityForm.dateFrom).format(),
        dateTo:moment(this.activityForm.dateTo).format(),
        images:this.images.map(image => image.path),
        videos:this.videos.map(function(v) {
          if(v.path != undefined){
            let newVideo = {
              videoUrl : v.path,
              videoTitle : v.videoTitle
            }
            v = newVideo
            return v
          }
        }),
        files:this.files.map(f => f.path),
        youTubeVideo:this.activityForm.youTubeVideo,
        activityParticipationLabs: this.activityForm.activityParticipationLabs,
        muteNotifications: this.activityForm.muteNotifications
      }
      await store.dispatch(ACTIVITY_STEP_1, data)
      .then((data) => {
        this.dataIsUpdated = false
        if(save){
          Swal.fire({
            title:this.$t('msg_saved_data'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"}
          })
          return
        }
        this.activityForm.identifier = data.id
        let nextRoute = ""
        if(data.nextStep == "step2Debate" || data.nextStep == 'step2Survey' || data.nextStep == "step2Poll" || data.nextStep == "step2HeatMap" || data.nextStep == "step2Ux") nextRoute = "activities.step2"
        if(data.nextStep == "step3") nextRoute = "activities.step3"
        this.$router.push({ name: nextRoute, params: { id: data.id } })
      })
      .catch((error) => {
        if(error.error) this.errors.push(this.$t('error_videochat_duration'));
      })
    },
    async submitStep2Topics(save){
      this.errors = []
      if(!this.$refs.TopicsCreation.isValid()){
        this.errors.push(this.$t('error_topic_texts'))
        return
      }
      const minDebateTopics = this.$refs.TopicsCreation.debateTopicsMinForParticipation
      if(minDebateTopics > this.debateTopics.length || minDebateTopics < 0){
        this.errors.push(this.$t('error_topic_min'))
        return
      }
      await store.dispatch(ACTIVITY_STEP_2_TOPICS,{
        data:{debateTopics:this.debateTopics, debateTopicsMinForParticipation: minDebateTopics},
        id:this.$route.params.id,
      })
      .then((data) => {
        this.dataIsUpdated = false
        this.debateTopicsMinForParticipation = minDebateTopics
        if (save) {
          Swal.fire({
            title:this.$t('msg_saved_data'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"}
          })
          return
        }
        this.$router.push({ name: "activities.step3", params: { id: this.$route.params.id } })
      })
    },
    async submitStep2HeatMap(save){
      this.errors = []
      if(!this.$refs.HeatMapPointsCreation.isValid()){
        this.errors.push(this.$t('error_heatMap_texts'))
        return
      }
      await store.dispatch(ACTIVITY_STEP_2_HEATMAP,{
        data:{heatmapPointDefinitions:this.heatmapPointDefinitions, heatMapImages:this.heatMapImages},
        id:this.$route.params.id,
      })
      .then((data) => {
        this.dataIsUpdated = false
        if (save) {
          Swal.fire({
            title:this.$t('msg_saved_data'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"}
          })
          return
        }
        this.$router.push({ name: "activities.step3", params: { id: this.$route.params.id } })
      })
    },
    async submitStep2Survey(save) {
    this.errors = [];

    // Validación de preguntas
    if (!this.questions || this.questions.length === 0) {
        this.errors.push(this.$t('error_task_without_question'));
    }

    for (let i in this.questions) {
        const questionRef = this.$refs['QuestionEdit-' + this.questions[i].id];
        if (questionRef && questionRef.hasErrors()) {
            this.errors.push(this.$t('error_empty_question') + " " + this.questions[i].order);
        }
    }

    // Validación de reglas de navegación
    if (navigationRulesHasErrors(this.rules)) {
        Swal.fire({
            title: this.$t('msg_review_rules'),
            icon: "error",
            customClass: { popup: "swal-customError" }
        });
        return;
    }

    // Si hay errores, se detiene el proceso
    if (this.errors.length > 0) return;

    if (this.activityForm.type === 'WAT_POLL') {
        let q = {
            identifier: this.questions[0]?.id,
            questionText: this.questions[0]?.text,
            maxValue: this.questions[0]?.type === 'RANKING' ? this.questions[0].rankingMax : undefined,
            minValue: this.questions[0]?.type === 'RANKING' ? this.questions[0].rankingMin : undefined,
            type: this.questions[0]?.type === 'RANKING' ? this.questions[0].type : (this.questions[0]?.type === 'MULTISELECTION' ? 'MULTI_SELECT' : 'SINGLE_SELECT')
        };

        if (this.questions[0]?.type === "MULTISELECTION" || this.questions[0]?.type === "SELECTION") {
            q.options = {};
            for (let v of this.questions[0].values || []) {
                q.options[v.id] = v.value;
            }
        }

        store.dispatch((q.identifier ? COMMUNITY_UPDATE_ACTIVITY_QUESTIONS : COMMUNITY_ADD_ACTIVITY_QUESTIONS), { data: [q], activityId: this.activityForm.identifier })
            .then((data) => {
                this.dataIsUpdated = false;
                if (save) {
                    Swal.fire({
                        title: this.$t('msg_saved_data'),
                        icon: "success",
                        customClass: { popup: "swal-customSuccess" }
                    });
                    return;
                }
                this.$router.push({ name: "activities.step3", params: { id: this.$route.params.id } });
            });
        return;
    }

    let data = {
        questions: JSON.parse(JSON.stringify(this.questions || [])),
        navigationRules: { conditions: formatNavigationRules(this.rules, true) }
    };

    for (let q of data.questions) {
        if (q.placeholders != undefined && typeof q.placeholders == "string") {
            q.placeholders = q.placeholders.split('//');
        }
        if (q.textDescriptions != undefined && typeof q.textDescriptions == "string") {
            q.textDescriptions = q.textDescriptions.split('//');
        }
    }

    await store.dispatch(ACTIVITY_STEP_2_SURVEY, {
        id: this.$route.params.id,
        data: data,
    }).then((data) => {
        this.dataIsUpdated = false;
        if (save) {
            Swal.fire({
                title: this.$t('msg_saved_data'),
                icon: "success",
                customClass: { popup: "swal-customSuccess" }
            });
            return;
        }
        this.$router.push({ name: "activities.step3", params: { id: this.$route.params.id } });
    });
},

    async submitStep2WatUx(save){
      this.errors = []
      if(!this.$refs.WatUxDataCreation.isValid()){
        this.errors = this.$refs.WatUxDataCreation.errors
        return
      }
      this.uxData.checkpoints = this.checkpoints
      await store.dispatch(ACTIVITY_STEP_2_WATUX,{
        data:this.uxData,
        id:this.$route.params.id,
      })
      .then((data) => {
        this.dataIsUpdated = false
        if (save) {
          Swal.fire({
            title:this.$t('msg_saved_data'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"}
          })
          return
        }
        this.$router.push({ name: "activities.step3", params: { id: this.$route.params.id } })
      })
    },
    async submitStep3(save){
      this.errors = []
      let data = {
        publicActivity:this.activityForm.publicActivity != undefined ? this.activityForm.publicActivity : false,
        testerIds: this.members.added
      }
      if(this.activityForm.type == 'VIDEOCHAT' && this.activityForm.publicActivity){
        if (this.$refs.participants.validate() != false) this.errors.push(this.$t('error_videochat_participants_num'))
        if(this.errors.length>0) return
        data.videochatPublicParticipantsNum = this.activityForm.videochatPublicParticipantsNum
        data.videochatUserShareScreen = this.activityForm.videochatUserShareScreen
      } 
      if(this.activityForm.type == 'VIDEOCHAT'){
        if (this.$refs.password.validate() != false || !this.$refs.password.validatePassword()) this.errors = this.errors.concat([this.$t('error_videochat_password'), this.$t('error_new_password_not_valid')])
        if(this.errors.length>0) return
        data.videochatPublicPassword = this.activityForm.videochatPublicPassword
        data.videochatUserShareScreen = this.activityForm.videochatUserShareScreen
      } 
      this.showStep3Spinner = true
      await store.dispatch(ACTIVITY_STEP_3, {data:data,id:this.$route.params.id})
      .then((data) => {
        this.showStep3Spinner = false
        this.dataIsUpdated = false
        let activityId = this.$route.params.id
        let origin = this.origin
        let vm = this
        Swal.fire({
          text: vm.$t('success_activity_created'),
          icon: 'success',
          confirmButtonText: vm.$t('activity_go_link'),
          customClass:{
            popup:'swal-customSuccess'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            vm.$router.push({ name: 'activity.info', params: { origin, id: activityId } })
          }
        })
      })
    },
    exit(){
      this.$router.push({ name: 'activities' })
    },
    async fetchNavigationRules(){
      if(this.activityForm.survey != undefined){
        await store.dispatch(ACTIVITY_NAVIGATION_RULES,{
          surveyId:this.activityForm.survey.identifier,
        })
        .then((data) => {
          generateNavigationRules(this.rules, data)
        })
      }
    },
    async fetchActivity(){
      await store.dispatch(COMMUNITY_FETCH_ACTIVITY,{
        activityId:this.$route.params.id,
      })
      .then((data) => {
        if(data.raffleData == null || data.raffleData == undefined) data.activityRaffleData = {}
        else data.activityRaffleData = data.raffleData
        if(data.type == 'VIDEOCHAT'){
          if(data.videochatPublicPassword == undefined) data.videochatPublicPassword = ''
          if(data.videochatPublicParticipantsNum == undefined) data.videochatPublicParticipantsNum = ''
          if(data.videochatUserShareScreen == undefined) data.videochatUserShareScreen = false
        }
        if(data.images != undefined){
          this.images = data.images.map(function(image) {
            let img = {path:image}
            return img
          })
        }
        if(data.videoDatas != undefined){
          this.videos = data.videoDatas.map(function(video) {
            let v = {path:video.videoUrl,videoTitle:video.videoTitle}
            return v
          })
          delete data.videoDatas
        }
        if(data.files != undefined){
          this.files = data.files.map(function(file) {
            let f = {path:file,name:file.substr(file.lastIndexOf("/")+1)}
            return f
          })
        }
        if(data.uxData) {
          this.uxData = data.uxData != undefined ? data.uxData : {}
          this.checkpoints = data.uxData.checkpoints != undefined ? data.uxData.checkpoints : []
        }
        this.debateTopics = data.debateTopics != undefined ? data.debateTopics : []
        this.debateTopicsMinForParticipation = data.debateTopicsMinForParticipation != undefined ? data.debateTopicsMinForParticipation : 0
        this.heatmapPointDefinitions = data.heatmapPointDefinitions != undefined ? data.heatmapPointDefinitions : []
        this.heatMapImages = data.heatMapImages != undefined ? data.heatMapImages : []
        this.questions = data.surveyTask != undefined ? data.surveyTask.questions : []
        if(this.questions.length > 0){
          for(let q of this.questions){
            if(q.type == 'IMAGE_CHOICE' || q.type == 'MULTIPLEIMAGE_CHOICE'){
              q.imageValues = []
              q.imageValues = q.values
              delete q.values
            }
            if (q.placeholders != undefined && q.placeholders != null) {
               q.placeholders = q.placeholders.join('//');
             }
             if(q.textDescriptions != undefined && q.textDescriptions != null) {
               q.textDescriptions = q.textDescriptions.join('//')
             }
          }
        }
        this.activityForm = data
      })
    },
    async fetchMembers(filter,array){
      let publicTags = []
      let tags = []
      if(filter != null){
        for(let t of this.communityTags){
          if(filter.filter.split(",").includes(t)) tags.push(t)
        }
        for(let t of this.communityPublicTags){
          if(filter.filter.split(",").includes(t)) publicTags.push(t)
        }
      }
      const request = store.dispatch(COMMUNITY_FETCH_MEMBERS,{
        notSetCommunityMembers:true,
        order:'recent',
        nickname:filter!=null ?filter.nickname:undefined,
        filter:tags.length>0?tags:undefined,
        publicFilter:publicTags.length>0?publicTags:undefined,
      });

      this.currentRequest = request;
      const data = await request;
      if (this.currentRequest !== request) return;

      if(filter != null){
        this.members[array] = data.communityMembers.map(function(m){return m.identifier});
      }
      else this.members.all = data.communityMembers
    },
    async fetchParticipants(){
      await store.dispatch(COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL,{
        activityId:this.$route.params.id,
      })
      .then((data) => {
        for(let o of data.communityMembers){
          this.members.added.push(o.identifier)
        }
      })
    },
    checkDateErrors: function(){
      let isEmptyDate = false
      if(this.$refs.dateToPicker.validateDate()) isEmptyDate = true
      if(this.$refs.dateFromPicker.validateDate()) isEmptyDate = true
      if(isEmptyDate) return true
      let dateFrom = new Date(this.activityForm.dateFrom)
      let dateTo = new Date(this.activityForm.dateTo)
      let momentDate = new Date()
      if (dateFrom == 'Invalid Date' || dateTo == 'Invalid Date') {
        return true
      }
      else if (dateFrom.getTime()>= dateTo.getTime()) {
        return true
      }
      else{
        return false
      }
    },
    changedDate: function (event){
      const dateTo = new Date()
      const dateFrom = new Date(this.activityForm.dateFrom)
      if (this.activityForm.dateTo < this.activityForm.dateFrom || this.activityForm.dateTo == "" || this.activityForm.dateTo == undefined || this.activityForm.dateTo == null) {
        if(this.activityForm.type == 'VIDEOCHAT'){
          dateTo.setDate(dateFrom.getDate())
          dateTo.setHours(dateFrom.getHours()+3)
        }
        else dateTo.setDate(dateFrom.getDate()+1)
        this.activityForm.dateTo = dateTo
        // this.$refs.dateToPicker.$refs.datePicker.updateDate()
      }
    },
    imageHeaderUploadSuccess: function (file, response) {
      this.activityForm.headerImage = response.path
      this.$refs.imageHeaderUploader.reset()
      this.dataIsUpdated = true
      this.headerImageKey ++
    },
    imageHeaderDelete(){
      this.activityForm.headerImage = ''
      this.dataIsUpdated = true
      this.headerImageKey ++
    },
    imageDelete(file){
      this.images = _.filter(this.images, function (o) { return o.path != file.path });
      this.dataIsUpdated = true
    },
    imageUploadSuccess: function (file, response) {
      this.images.push({path:response.path,uuid:file.uuid})
      this.$refs.imageUploader.reset()
      this.dataIsUpdated = true
    },
    imageRaffleUploadSuccess: function (file, response) {
      this.activityForm.activityRaffleData.prizeImage = response.path
      this.$refs.raffleImageUploader.reset()
      this.dataIsUpdated = true
    },
    changesDetected() {
      this.dataIsUpdated = true
    },
    removeVideo(file,index){
      this.videos.splice(index,1)
      this.dataIsUpdated = true
    },
    videoUploadSuccess: function (file, response) {
      this.videos.push({path:response.path,uuid:file.uuid, videoTitle:""})
      this.$refs.videoUploader.reset()
      this.dataIsUpdated = true
    },
    removeFile(file){
      this.files = _.filter(this.files, function (o) { return o.path != file.path });
      this.dataIsUpdated = true
    },
    fileUploadSuccess: function (file, response) {
      this.files.push({path:response.path,uuid:file.uuid,name:file.name})
      this.$refs.fileUploader.reset()
      this.dataIsUpdated = true
    },
    createQuestion(type){
      if (this.activityForm.type=='WAT_POLL') this.questions = []
      createQuestion(this.questions, type)
      this.dataIsUpdated = true
      document.getElementById('QuestionSurveyCreation').scrollIntoView({behavior: 'smooth', block: 'start'})
    },
    duplicateQuestion(question){
      duplicateQuestion(question, this.questions)
      this.dataIsUpdated = true
    },
    canRemoveQuestion(questionIndex){
      canRemoveQuestion(questionIndex, this.questions, this.rules, this.$t)
      this.dataIsUpdated = true
    },
    generatePollQuestion(){
      if (!this.activityForm.communityQuestions[0]) {
        return;
      }
      let question = this.activityForm.communityQuestions[0]
      let q = {
        id : question.identifier,
        order: 1,
        text : question.questionText,
        rankingMax : question.type == 'RANKING' ? question.maxValue : undefined,
        rankingMin : question.type == 'RANKING' ? question.minValue : undefined,
        type : question.type == 'RANKING' ? question.type : (question.type == 'MULTI_SELECT' ? 'MULTISELECTION' : 'SELECTION')
      }
      if(question.type == "SINGLE_SELECT" || question.type == "MULTI_SELECT"){
        q.values = []
        for(let i in this.activityForm.communityQuestions[0].options){
          q.values.push({
            id:i,
            order:i,
            value:this.activityForm.communityQuestions[0].options[i].value
          })
        }
      }
      this.questions.push(q)
      this.dataIsUpdated = true
    },
    addRule(q){
      addRule(this.rules, q)
      this.dataIsUpdated = true
    },
    addDebateTopic:async function(topic){
      this.debateTopics.push(topic)
      this.dataIsUpdated = true
    },
    deleteDebateTopic(index){
      this.debateTopics.splice(index,1)
      this.dataIsUpdated = true
    },
    addHeatMapPoint(point){
      this.heatmapPointDefinitions.push(point)
      this.dataIsUpdated = true
    },
    deleteHeatMapPoint(index){
      this.heatmapPointDefinitions.splice(index,1)
      this.dataIsUpdated = true
    },
    addCheckpoint(checkpoint){
      this.checkpoints.push(checkpoint)
      this.dataIsUpdated = true

    },
    deleteCheckpoint(index){
      this.checkpoints.splice(index,1)
      this.dataIsUpdated = true
    },
    isQuestionsActivity(){
      return this.activityForm.type == "WAT_SURVEY" || this.activityForm.type == 'WAT_POLL'
    },
    removeQuestionValue(event, index){
      removeQuestionValue(this.questions, index, event)
      if (this.questions[index].type == 'MULTISELECTION') {
        this.questions[index].multiselectionMax = this.questions[index].values.length
      }
      if (this.questions[index].type == 'MULTIPLEIMAGE_CHOICE') {
        this.questions[index].multiselectionMax = this.questions[index].imageValues.length
      }
      this.dataIsUpdated = true
    },
    removeQuestionValueOption(event, index){
      removeQuestionValueOption(this.questions, index, event)
      if (this.questions[index].type == 'MULTISELECTION') {
        this.questions[index].multiselectionMax = this.questions[index].values.length
      }
      if (this.questions[index].type == 'MULTIPLEIMAGE_CHOICE') {
        this.questions[index].multiselectionMax = this.questions[index].imageValues.length
      }
      this.dataIsUpdated = true
    },
    addValue(question, addTxt){
      addValue(question, addTxt)
      if (question.type == 'MULTISELECTION') {
        question.multiselectionMax = question.values.length
      }
      if (question.type == 'MULTIPLEIMAGE_CHOICE') {
        question.multiselectionMax = question.imageValues.length
      }
      this.dataIsUpdated = true
    },
    addOptionMatrix(question){
      addOptionMatrix(question)
      this.dataIsUpdated = true
    },
    reorderQuestions(){
      reorderQuestions(this.questions)
      this.dataIsUpdated = true
    },
    getSVG(){
      return "<svg width='26' height='24' viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(#clip0)'><path d='M24.914 11.5128H13.3934V0.399217C13.3934 0.187581 13.2155 0.0159912 12.9961 0.0159912C12.7767 0.0159912 12.5988 0.187581 12.5988 0.399217V11.5128H1.07817C0.858783 11.5128 0.680908 11.6844 0.680908 11.896C0.680908 12.1076 0.858783 12.2792 1.07817 12.2792H12.5988V23.3928C12.5988 23.6044 12.7767 23.776 12.9961 23.776C13.2155 23.776 13.3934 23.6044 13.3934 23.3928V12.2792H24.914C25.1334 12.2792 25.3113 12.1076 25.3113 11.896C25.3113 11.6844 25.1334 11.5128 24.914 11.5128Z' fill='#828282'/></g><defs><clipPath id='clip0'><rect width='24.6304' height='23.76' fill='white' transform='translate(0.680908 0.0159912)'/></clipPath></defs></svg>"
    },
  },
};
</script>

<style lang="scss" scoped>
.ActivityCreation{
  height: calc(100vh - 70px);
  overflow-y: auto;
  font-family: Roboto Condensed;
  font-style: normal;

  .LoadingIcon {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  &::-webkit-scrollbar-track{
    background-color: #e6e6e6;
  }
  &::-webkit-scrollbar {
    background-color: #8134ff;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: var(--primary-color);
  }
  &--Header{
    height: 150px;
    background: rgba(0, 0, 0, 0.22);
  }
  &__Content{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: -90px;
    margin-bottom: 10px;
    &--Header{
      display:flex;
      justify-content: space-between;
      width:100%;
      padding-left:100px;
      padding-right:130px;
      z-index:1;
    }
    &--pageTitle{
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 43px;
      color: white;
      text-transform: uppercase;
    }
    &--Steps{
      display:flex;
      text-transform: uppercase;
      div.active{
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        color: white;
      }
      div.completed{
        color:white;
        background: var(--accent-color);
      }
      div{
        width: 64px;
        height: 64px;
        border-radius: 64px;
        border: 3px solid white;
        text-align: center;
        background: #E0E0E0;
        color:#B5B5B5;
        margin-left:23px;
        &:first-child{
          margin-left:0px;
        }
        p{
          &:first-child{
            font-family: Roboto Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 29px;
          }
          &:last-child{
            font-family: Roboto Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 8px;
          }
        }
      }
    }
    &--Box{
      box-shadow: 3px -4px 22px rgba(0, 0, 0, 0.15);
      background: white;
      margin:-22px 100px 47px 100px;
      width: calc(100% - 170px);
      padding-top:80px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.withQuestions{
        padding-top:0px;
      }
    }
    &--QuestionBox{
      margin:-22px 100px 47px 100px;
      width: calc(100% - 170px);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__HeaderImage{
    background: white;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    margin-bottom: 20px;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
    border: 1px solid #E1E1E1;
    position: relative;
    p{
      margin-bottom: 0;
    }
    span{
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      color: red;
      cursor: pointer;
    }
  }
  &__TitleLabs{
    display: grid;
    grid-template-columns: 85% auto;
    grid-gap: 15px;
    p{
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      color: #3E3E3E;
      margin-bottom: 10px;
    }
  }
  &__Raffle, &--NotifActivation{
    margin-top: 20px;
  }
  &--RaffleTitle, &--NotifActivationTitle{
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    color: #3E3E3E;
  }
  &--RaffleActivation, &--NotifActivation{
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
  &--RaffleInfo{
    background: #cfe2ff;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #052c65;
    padding: 12px 13px;
    width: 100%;
  }
  &--RaffleContent{
    border: 1px solid lightgray;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  &--RaffleImage{
    display: flex;
    gap: 10px;
  }
  &__ActivitySelector{
    margin-top: 40px;
    margin-bottom: 34px;
    &--title{
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      color: #3E3E3E;
      margin-bottom: 10px;
    }
    &--Activities{
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto;
      grid-gap: 15px;
    }
    &--Activity{
      display: grid;
      grid-gap:16px;
      grid-template-columns:28px auto;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
      padding: 15px 25px 6px 17px;
      cursor: pointer;
      &.active{
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        border: 1px solid #E1E1E1;
        h1,p{
          color: white;
        }
        img{
          filter: brightness(0) invert(1);
        }
        &:before{
          color: white;
          font-size: 21px;
          position: absolute;
          content:"\f058";
          font-family: "Font Awesome 5 Free";
          right: 15px;
          top: 14px;
        }
      }
    }
    &--logo{
      width: 28px;
      height: 28px;
      img{
        width: 28px;
        height: 28px;
      }
    }
    &--info{
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      h1{
        color: #3E3E3E;
        font-weight: bold;
        font-size: 22px;
      }
      p{
        color: #747474;
        font-size: 15px;
        margin-top: 6px;
      }
    }
  }
  &__Dates{
    display: grid;
    margin-top: 19px;
    grid-gap: 16px;
    grid-template-columns: auto auto;
  }
  &__YtVideo{
    margin-top: 19px;
  }
  &__Media{
    margin-top: 56px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 30% 30% 30%;
    h1{
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      letter-spacing: -0.01em;
      color: #3E3E3E;
    }
    &--List{
      display: flex;
      flex-wrap: wrap;
    }
    &--Uploader{
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      color: #828282;
      width: 150px;
      height: 95px;
      margin:0 10px 10px 0;
    }
    &--Item{
      width: 150px;
      height: 95px;
      margin:0 10px 10px 0;
      position:relative;
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      img{
        width: 100%;
        height:100%;
      }
      video{
        width: 100%;
        height: 61px;
      }
      div{
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        padding: 10px;

        span{
          font-size: .8em;
          word-break: break-all;
          color: white;
        }
      }
      input{
        width: 100%;
        background: #F5F5F5;
        border:none;
        border-top: 1px solid #D3D3D3;
        height: initial;
        font-size: 15px;
        padding: 4px;
      }
      i{
        position:absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        color:#721c24;
      }
    }
    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction:column;
      align-items: center;
  }
  }
  &__Topics,&__HeatMap,&__WatUx{
    width:90%;
  }
  &__QuestionDrag{
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  &__Question{
    &:first-of-type{
      padding-top: 100px;
    }
  }
  &__Survey, &__Step3{
    width: 90%;
  }
  &__Actions{
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 26px auto 28px auto;
    padding:23px 0 0 0;
    border-top: 1px solid #E0E0E0;
    button{
      font-size: 21px;
      min-width: 173px;
      height: 42px;
      margin-left: 16px;
      @media only screen and (max-width: 600px) {
        margin-left: 0;
  }
    }
    &--Next{
      background: var(--accent-color)!important;
    }
    &--Previous{
      background: #747474!important;
    }
  }
  &__Options{
    position: fixed;
    z-index: 1;
    top: 250px;
    right:0;
    display: grid;
    grid-gap: 9px;
    grid-template-columns: auto;
    @media only screen and (max-width: 600px) {
      display: none;
  }

    button{
      width: 58px;
      height: 58px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      text-align: center;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      outline:none;
      position: relative;

    }
    &--Exit, &--Logic{
      background: #F7F4F8;
      color: #3E3E3E;
    }
    &--Next{
      background: var(--accent-color);
      color: white;
    }
    svg{
      margin-bottom: 7px;
    }
  }
  &__Members{
    margin-top: 35px;
    &.opacity{
      opacity: 0.3;
      pointer-events:none
    }
  }
  &__Visibility{
    h1{
      font-size: 19px;
      letter-spacing: -0.01em;
      color: #3E3E3E;
      font-weight: normal;
      margin-bottom: 10px;
    }
    &--Types{
      display: flex;
      h1{
        font-size: 22px;
      }
      p{
        color: #747474;
        font-size: 15px;
      }
      .BoxShadow{
        &:first-of-type{
          margin-right: 26px;
        }
        &.active{
          p, h1, svg{
            color: white;
            path{
              fill: white;
            }
          }
        }
        svg{
          width: 100px;
          margin-right: 13px;
        }
      }
    }
  }
  &__VideoChatPublic{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
    h1{
      font-size: 19px;
      letter-spacing: -0.01em;
      color: #3E3E3E;
      font-weight: normal;
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
  &__VideoChatUsers{
    h1{
      font-size: 19px;
      letter-spacing: -0.01em;
      color: #3E3E3E;
      font-weight: normal;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    div{
      background: #f4f4f4;
      border: 1px solid #e4e4e4;
      padding: 5px 10px;
    }
  }
  @media (max-width: 848px) {
    &__Content--Header{
      padding-left:70px;
      padding-right:100px;
    }
    &__Content--pageTitle{
      font-size:40px;
    }
    &__Content--Box, &__Content--QuestionBox{
      margin-left:50px;
      margin-right:80px;
      width: calc(100% - 130px);
    }
    &__Content--Steps div{
      margin-left: 15px;
    }
  }
  @media (max-width: 767px) {
    &__Content--Header{
      padding-left:50px;
      padding-right:80px;
    }
    &__Content--pageTitle{
      font-size:40px;
    }
    &__Content--Box,&__Content--QuestionBox{
      margin-left:30px;
      margin-right:60px;
      width: calc(100% - 90px);
    }
    &__Content--Steps div{
      margin-left: 15px;
      width: 57px;
      height: 57px;
      border-radius: 57px;
    }
    &__Media{
      grid-template-columns: auto;
    }
  }
  @media (max-width: 595px) {
    &__Content--Header{
      padding-left:10px;
      padding-right:10px;
    }
    &__Content--pageTitle{
      font-size:18px;
    }
    &__Content--Box, &__Content--QuestionBox{
      margin-left:10px;
      margin-right:10px;
      width: calc(100% - 20px);
    }
    &__Content--Steps div{
      margin-left: 10px;
      width: 45px;
      height:45px;
      border-radius: 45px;
      p:last-child{
        font-size: 7px;
      }
      p:first-child{
        font-size: 21px;
      }
    }
    &__Form input{
      height: 45px;
      font-size:25px;
    }
    &__ActivitySelector--Activities{
      grid-template-columns: auto;
    }
    &__Actions{
      flex-direction: column;
      button{
        margin-top: 5px;
      }
    }
    &__Visibility--Types{
      flex-direction: column;
      .BoxShadow:first-of-type{
        margin-bottom: 5px;
        margin-right: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.ExecutionPreview{
  .ModalFullScreen--Modal{
    width: 98%;
    padding:0;
    background:url('./../assets/img/waves-bg.png'),var(--primary-color);
    .Survey{
      background: linear-gradient(269.66deg, rgba(0, 0, 0, 0.4) 3.24%, transparent 96.38%);
    }
  }
}
.ActivityCreation .CommunityErrors{
  margin-left: auto!important;
  margin-right: auto!important;
  width: 90%;
}
.ActivityCreation__Form{
  width:90%;
}
.ActivityCreation__Dates{
  input{
    background: #F5F5F5;
    border: 1px solid #E1E1E1;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: #969696;
    height: 47px;
    &::placeholder{
      opacity: 0.6;
    }
  }
  @media only screen and (max-width: 600px) {
    display:flex!important;
    flex-direction:column;
  }
}
.ActivityCreation__TitleLabs{
  input{
    font-size: 32px!important;
    height: 67px;
    padding: 16px;
  }
  @media only screen and (max-width: 600px) {
    display: flex!important;
    flex-direction: column;
  }
}
.ActivityCreation__Labs{
  input{
    width: 80px!important;
  }
}
.UserAssignment{
  @media (max-width: 965px) {
    grid-template-columns: calc(55% - 7.5px) calc(45% - 7.5px)!important;
  }
  @media (max-width: 640px) {
    grid-template-columns: 100%!important;
  }
}
.GeneralRules{
  .NavigationRules--Wrapper{
    max-height: calc(100vh - 350px);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
      border-radius: 10px;
      height: 20px;
    }
    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 2px;
      border-radius: 10px;
      height: 20px;

    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: var(--primary-color);
      height: 20px;

    }
  }
}
.ActivityCreation__Question{
  &:first-of-type{
    padding-top: 100px;
    .QuestionSurvey--Data{
      border-top: none;
    }
  }
}
</style>
