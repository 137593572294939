
import { defineComponent } from 'vue';
import SwitchInput from '../SwitchInput/SwitchInput.vue'
import { isQuestionWithValues } from '../../utils/survey-creation.ts'
import Dropzone from '../Dropzone';

export default defineComponent({
  name : 'QuestionConfig',
  components:{
    SwitchInput,
    Dropzone
  },
  props:{
    question: {
      type: Object,
      default: {images:[]}
    },
    filePath: String,
    videoPath: String,
    headers: Object
  },
  computed:{
    optionsVisible(){
      return this.selectedType != null
    }
  },
  data(){
    return {
      options: [
        {
          value: 'IMAGE_DISPLAY_MINIATURE', labelKey: 'image_display_type_miniature', description: 'Se muestra en un tamaño reducido para optimizar el espacio en pantalla. El usuario puede ampliarla haciendo clic sobre ella para verla con mayor detalle.' },
        {
          value: 'IMAGE_DISPLAY_CAROUSEL', labelKey: 'image_display_type_carrousel', description: 'Presenta la imagen en un tamaño estándar. Para ampliar una imagen específica, el usuario puede hacer zoom con un doble clic.' },
        {
          value: 'IMAGE_DISPLAY_MAX_SIZE', labelKey: 'image_display_type_max_size', description: 'La imagen se muestra en su tamaño real, permitiendo visualizar todos los detalles sin necesidad de ampliación adicional.' }
      ],
      selectedType: null,      
    }
  },
  methods:{
    selectType(value:any){
      this.$emit('data-updated')
      this.selectedType = this.getSelectedTypeDescription(value)
    },
    getSelectedTypeDescription(value:any){
      return this.options.find((option:any) => option.value == value)
    },
    isQuestionRandom(){
      return isQuestionWithValues(this.question.type) && this.question.type != "DIVIDE_POINTS"
    },
    isTextQuestion(){
      return this.question.type == 'TEXT' || this.question.type == 'MULTITEXT' || this.question.type == 'TEXTAREA'
    },
    imageUploadSuccess(file: any, response: any){
      if(this.question.images == undefined || this.question.images == null) this.question.images = []
      this.question.images.push(response.path)
      const uploader: any = this.$refs.imageUploader
      uploader.reset()
      this.question.imageDisplayType = 'IMAGE_DISPLAY_MINIATURE'
    },
    videoUploadSuccess(file: any, response: any){
      this.question.video = response.path
      const uploader: any = this.$refs.videoUploader
      uploader.reset()
    },
    deleteImage(i: any){
      this.question.images.splice(i,1)
    },
    deleteVideo(i: any){
      this.question.video = undefined
    },
  }
})
