<template>
<div class="QuestionIMG">
  <img v-if="name=='RATING_FACES'" src="../../assets/img/QuestionTypes/rating_faces.svg" alt="">
  <img v-if="name=='SELECTION'" src="../../assets/img/QuestionTypes/selection.svg" alt="">
  <img v-if="name=='MULTISELECTION'" src="../../assets/img/QuestionTypes/multiselection.svg" alt="">
  <img v-if="name=='RANKING-STARS'" src="../../assets/img/QuestionTypes/ranking_stars.svg" alt="">
  <img v-if="name=='RANKING-NUMBERS'" src="../../assets/img/QuestionTypes/ranking_numbers.svg" alt="">
  <img v-if="name=='SORTING'" src="../../assets/img/QuestionTypes/sorting.svg" alt="">
  <img v-if="name=='DIVIDE_POINTS'" src="../../assets/img/QuestionTypes/divide_points.svg" alt="">
  <img v-if="name=='IMAGE_CHOICE'" src="../../assets/img/QuestionTypes/image_choice.svg" alt="">
  <img v-if="name=='MULTIPLEIMAGE_CHOICE'" src="../../assets/img/QuestionTypes/multi_image_choice.svg" alt="">
  <img v-if="name=='IMAGE_URL'" src="../../assets/img/QuestionTypes/image_url.svg" alt="">
  <img v-if="name=='DATE'" src="../../assets/img/QuestionTypes/date.svg" alt="">
  <img v-if="name=='MATRIX' && !matrixRadioButton" src="../../assets/img/QuestionTypes/matrix.svg" alt="">
  <img v-if="name=='matrix-options' || matrixRadioButton" src="../../assets/img/QuestionTypes/matrix-radio.svg" alt="">
  <img v-if="name=='NUMBER'" src="../../assets/img/QuestionTypes/number.svg" alt="">
  <img v-if="name=='MULTINUMBER'" src="../../assets/img/QuestionTypes/multinumber.svg" alt="">
  <img v-if="name=='LABEL'" src="../../assets/img/QuestionTypes/Label.svg" alt="">
  <img v-if="name=='INTERNAL_VARIABLE'" src="../../assets/img/QuestionTypes/internal_variable.svg" alt="">
  <img v-if="name=='AGE'" src="../../assets/img/QuestionTypes/age.svg" alt="">
  <img v-if="name=='GENDER'" src="../../assets/img/QuestionTypes/gender.svg" alt="">
  <img v-if="name=='PROVINCE'" src="../../assets/img/QuestionTypes/geo.svg" alt="">
  <img v-if="name=='HEATMAP'" src="../../assets/img/QuestionTypes/heatmap-icon.svg" alt="">
  <img v-if="name=='TEXT'" src="../../assets/img/QuestionTypes/text.svg" alt="">
  <img v-if="name=='TEXTAREA'" src="../../assets/img/QuestionTypes/textarea.svg" alt="">
  <img v-if="name=='MULTITEXT'" src="../../assets/img/QuestionTypes/multitext.svg" alt="">
  <img v-if="name=='TASK_WAT_UX_QUANTI'" src="../../assets/img/QuestionTypes/watuxquanti.svg" alt="">
  <img v-if="name=='TASK'" src="../../assets/img/QuestionTypes/task.svg" alt="">
  <div class="QuestionIMG--WatUx" v-if="name == 'WATUX'">
    <i class="fas fa-desktop"></i>
  </div>
</div>
</template>

<script>
  export default {
    name: 'QuestionIMG',
    props: {
      name:{
        type:String,
      },
      matrixRadioButton:{
        type: Boolean,
        default: false
      }
    },
  }
</script>

<style scoped lang="scss">
.QuestionIMG--WatUx{
  background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 28px;
}
.QuestionSurvey--Logo{
  img {
    width: 40px;
  }
}
</style>
